import React from "react"
import WhatWeDoCard from "../../UI/WhatWeDoCard"
import whatWeDoData from "../../../data/components/whatWeDo"
import { Container } from "./style"
import { H2Title, Section, DoubleGrid, GrayBorder } from "../../../globalStyle"

const ServicesComponent = () => {
  return (
    <Section style={{ marginTop: "100px" }}>
      <Container>
        <H2Title marginLeft="15px">Services</H2Title>
      </Container>
      <GrayBorder />
      <Container>
        <DoubleGrid>
          {whatWeDoData.map(c => (
            <WhatWeDoCard key={c.id} data={c} />
          ))}
        </DoubleGrid>
      </Container>
    </Section>
  )
}

export default ServicesComponent
