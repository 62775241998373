import React from "react"
import Layout from "../components/UI/Layout"
import Head from "../components/Head"
import ServicesComponent from "../components/Sections/Services"

const Services = () => {
  return (
    <Layout>
      <Head
        description="Develop your product with Oakfusion 🚀 We create web and mobile applications for startups. We will estimate it for free ✅ Discuss your project with us"
        pageTitle="Services | What we can do for you"
        keywords="software, oakfusion"
      />
      <ServicesComponent />
    </Layout>
  )
}

export default Services
